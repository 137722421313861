{
  "name": "br-app",
  "version": "2.2.6-afefec91.0",
  "license": "Apache-2.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/forms": "^18.2.5",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@swimlane/ngx-charts": "^20.5.0",
    "html2canvas": "^1.4.1",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.5",
    "@angular-eslint/builder": "18.3.1",
    "@angular-eslint/eslint-plugin": "18.3.1",
    "@angular-eslint/eslint-plugin-template": "18.3.1",
    "@angular-eslint/schematics": "18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cdk": "^18.2.4",
    "@angular/cli": "~18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@angular/material": "^18.2.4",
    "@angular/material-luxon-adapter": "^18.2.4",
    "@sparbanken-syd/kalpylator": "^0.6.3",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sdc-signing": "^1.0.2",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.1.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@sparbanken-syd/sparbanken-tax": "^0.5.1",
    "@types/d3-scale": "^4.0.8",
    "@types/d3-selection": "^3.0.10",
    "@types/d3-shape": "^3.1.6",
    "@types/jasmine": "~5.1.4",
    "@types/luxon": "^3.4.2",
    "@types/node": "^20.12.7",
    "@types/sparbanken-syd-auth-backend": "^2.0.0",
    "@types/sparbanken-syd-datastore": "~0.4.1",
    "@typescript-eslint/eslint-plugin": "^7.7.1",
    "@typescript-eslint/parser": "^7.7.1",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^48.2.3",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.4.4",
    "postcss": "^8.4.38",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.4.0",
    "stylelint-config-sass-guidelines": "^11.1.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-scss": "^6.2.1",
    "typescript": "~5.4.5"
  }
}
